@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-SemiBold.woff2') format('woff2'),
  url('../fonts/Raleway-SemiBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway-ExtraLight.woff2') format('woff2'),
  url('../fonts/Raleway-ExtraLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


//global font-size (small, medium, large)
 main {
   font-size: rem-calc(17);
   @include breakpoint(medium) {
     font-size: rem-calc(20);
   }
 }

//main {
//  //Dynamic Font Size
//  @include font-size(1,1.5);
//}

a:focus {
  outline: none;
}

a.go-back {
  font-size: 0.8em;
  @include breakpoint(medium) {
    margin-top: 3.9em;
    display: inline-block;
  }
  .svg-icon {
    transition: all .15s ease-in-out;
    transform: rotate(-90deg);
    font-size: 0.6em;
    margin-right: rem-calc(3);
    vertical-align: -0.3em;
    .icon {
      fill: $gray;
    }
  }
  &:hover {
    .svg-icon {
      transform: rotate(-110deg);
    }
  }
}

h1 {
  font-weight: normal;
  margin-bottom: 2em;
  &.margin-top {
    margin-top: 3em;
  }
}


h2 {
  &.proj-title {
    padding-top: 1.5em;
    padding-bottom: 4em;
    font-weight: normal;
  }
}