// html.sr {
//   .fade-in-interval, .fade-in-news, .fade-in-bottom {
//     visibility: hidden;
//   }
// }
.full-link {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.content-margin-bottom {
  margin-bottom: 5em;
}