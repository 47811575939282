.h-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  &.opaque {
    //@include shadow(0 0 0 1px rgba($gray, 0.5));
  }
}
