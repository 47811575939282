img {
  image-rendering: -webkit-optimize-contrast;
}

figure {
  margin-bottom: 2em;
  img {
    width: 100%;
  }
}



.home-img-wrapper {
  max-width: rem-calc(1460);
  margin: 0 auto;
  figure.home-img {
    //width: 100%;
    //height: calc(100vh - 65px);
    //position: relative;
    //overflow: hidden;
    margin: 0 0 20px 0;
    //@include breakpoint(large) {
    //  height: calc(100vh - 85px);
    //  margin-bottom: rem-calc(40);
    //}

    img {
      //object-fit: cover;
      //height: 100%;
      width: 100%;
    }
  }
}