footer {
  background-color: $white;
  color: $gray;
  padding: rem-calc(30 0);
  font-size: rem-calc(16);
  a {
    color: $gray;

    .svg-icon {
      height: 1.8em;
      width: 1.9em;
      font-size: 0.9em;
    }

    &:hover {
      color: $black;
      .icon {
        fill: $black;
      }
    }
    .icon {
      fill: $gray;
    }
  }
}

