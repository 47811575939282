.logo {
  padding: rem-calc(18 10 18 0);
  @include breakpoint(large) {
    padding: rem-calc(30 20 30 0);
  }
  a {
    display: inline-block;
    img {
      width: 260px;
      height: auto; //immer width und height in PX angeben (IE)
      @include breakpoint(medium) {
        width: 300px;
      }
      @include breakpoint(large) {
        width: 420px;
      }
    }
  }
}
