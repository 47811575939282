

.proj-image-list {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    @include breakpoint(medium) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      .grid-element {
        img {
          width: 100%;
          object-fit: cover;
          aspect-ratio: 1.5;
        }

        &.rows-2 {
          grid-area: span 2/span 1;

          img {
            aspect-ratio: 0.732;
          }
        }
      }
    }
}

.proj-teaser-box {
  position: relative;
  overflow: hidden;
  .proj-teaser-img {
    aspect-ratio: 1.4;
    width: 100%;
    object-fit: cover;
  }
  .overlay {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: rgba($black,0.8);
    opacity: 0;
    transition: opacity .2s ease-in-out;
    transform: translateX(-100%);
    h2 {
      color: $white;
      font-weight: normal;
      top: 50%;
      position: absolute;
      width: 100%;
      text-align: center;
      padding: 0 20px;
      transform: translateY(-50%);
    }
  }
  &:hover {
    .overlay {
      opacity: 1;
      transform: translateX(0);
    }
  }
}