//html {
//  box-sizing: border-box;
//}
//*, *:before, *:after {
//  box-sizing: inherit;
//}
//
//@mixin center() {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
//
//body {
//  margin: 0;
//  height: 100vh;
//  @include center;
//}

.slider-container {
  position: relative;
  width: 100%;
  //height: 600px;
  border: 2px solid white;
  margin-bottom: rem-calc(30);
  user-select: none;
  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    pointer-events: none;
  }
  .size-img {
    position: relative;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }
  .background-img {
    //position: relative;
  }
  .foreground-img {
    width: 50%;
    border-right: 4px solid $white;
    img {
      min-width: 100%;
      max-width: none;
      height: 100%;
      object-fit: cover;
    }
  }

  .slider-button {
    $size: 30px;
    pointer-events: none;
    position: absolute;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: white;
    left: calc(50% - 17px);
    top: calc(50% - 18px);
    display: flex;
    justify-content: center;
    align-items: center;
    @mixin arrow-helper() {
      content: '';
      padding: 3px;
      display: inline-block;
      border: solid #5D5D5D;
      border-width: 0 2px 2px 0;
    }
    &:after {
      @include arrow-helper();
      transform: rotate(-45deg);
    }
    &:before {
      @include arrow-helper();
      transform: rotate(135deg);
    }
  }
}